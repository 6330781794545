.dot-text{
    position: relative;
}
.dot-text::before{
    content: '';
    position: absolute;
    top: 50%;
    left: -20px;
    transform: translateY(-50%);
    background: #FCAC00;
    width: 11px;
    height: 11px;
    border: #11128C solid 2px;
    border-radius: 50%;
}
input:focus-visible{
    outline: none !important;
    border: 0 !important;
}
::placeholder {
    color: #FCAC00 !important;
}
::-webkit-input-placeholder {
       color: #FCAC00 !important;
}

:-moz-placeholder {
       color: #FCAC00 !important;
}

::-moz-placeholder {
       color: #FCAC00 !important;
}

:-ms-input-placeholder {
       color: #FCAC00 !important;
}

::input-placeholder {
       color: #FCAC00 !important;
}

::placeholder {
       color: #FCAC00 !important;
}




#slider label:focus-visible {
       outline: none !important;
}

#slider label {
       position: absolute;
       user-select: none;
       z-index: 2;
       width: 100%;
       left: 0%;
       height: 100%;
       pointer-events: none;
       flex-direction: column;
       
       top: 0;
       cursor: pointer;
       display: flex;
       align-items: center;
       justify-content: center;
       transition: transform 800ms ease  ;
       transition-property: opacity, transform;

       transition-duration: 500ms;
}


body{
       max-width: 1920px;
       margin: 0 auto;
       /* display: flex; */
       /* justify-content: center; */

}